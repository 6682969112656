import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import makeup from './photo4.png';

function Makeup() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <p style={{ color: "white" }}>
        <h2 style={{ fontSize: "1.9em" }}>
        Makeup <span className="purple"> for </span> Women.
        </h2>
        Michelle's is an entrepreneur with a passion for beauty products.
        </p>
        <Row className="justify-content-center">
          <Col md={4} className="d-flex justify-content-center">
            <img src={makeup} className="img-fluid" alt="avatar" />
          </Col>
        </Row>

      </Container>
    </Container>
  );
}

export default Makeup;