import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import Home2 from "./Home2";
import Type from "./Type";

function Home() {
  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Particle />
        <Container className="home-content">
          <Row>
            <Col md={7} className="home-header">
              <h1 className="heading-name">
                <strong className="main-name"> La Barbie </strong>
              </h1>
              <div style={{ padding: 50, textAlign: "left" }}>
                <Type />
              </div>
            </Col>
            <p>&nbsp;&nbsp;&nbsp;</p>
            <Col>
              <h2>
               Acerca de <strong className="main-name"> Michelle </strong>
              </h2>
              <p>
                Michelle Casamayor es una peleadora de MMA, modela profesional y empresaria.
                Ella ama la confluencia de la feminidad y la dureza, desafiando la noción convencional de que las mujeres no pueden lograr ambas.
                Michelle es una aspirante a empresaria de productos de belleza y tiene la intención de algún día retribuir abriendo un refugio para mujeres.
                Ella es una orgullosa Venezolana, actualmente vive en Medellín, Colombia.
              </p>
            </Col>
          </Row>
        </Container>
      </Container>
      <Home2 />
    </section>
  );
}

export default Home;
