import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Tilt from "react-parallax-tilt";
import Button from "react-bootstrap/Button";
import img from './myIMG.png';

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              My <span className="purple"> Medios & Contacto </span> 
            </h1>
            <h2 style={{ fontSize: "1.1em" }}>
              Correo Electrónico: <span className="purple"> casamayormichelle.1@gmail.com </span> 
            </h2>
      <p>
        <Button variant="primary" href="https://www.tapology.com/fightcenter/fighters/338550-michelle-casamayor-la-barbie" target="_blank">
          {"Tapology"}
        </Button>
      </p>
      <p>
        <Button variant="primary" href="https://www.instagram.com/michellelabarbiedelmma/" target="_blank">
          {"Instagram"}
        </Button>
      </p>
      <p>
        <Button variant="primary" href="https://www.youtube.com/@michellelabarbiecasamayor7565">
          {"YouTube"}
        </Button>
      </p>
      <p>
        <Button variant="primary" href="https://www.elcolombiano.com/deportes/barbie-mma-artes-marciales-mixtas-colombia-ufc-OA25523962" target="_blank">
          {"Story"}
        </Button>
      </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={img} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
