import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import meesh2 from './meesh-2.png';
import meesh3 from './meesh-3.png';
import meesh4 from './meesh-4.png';
import meesh5 from './meesh-5.png';

function MMA() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <p style={{ color: "white" }}>
        <h2 style={{ fontSize: "1.9em" }}>
        La <span className="purple"> Barbie </span> 
        </h2>
        Michelle is fighting out of Medellin, Colombia. Her fighting style is Judo and Boxing.
        </p>
        <h2 style={{ fontSize: "1.9em" }}>
          <span className="purple"> Training </span> 
        </h2>
        <Row className="justify-content-center">
          <Col md={4} className="d-flex justify-content-center">
            <img src={meesh4} className="img-fluid" alt="avatar" />
          </Col>
          <Col md={4} className="d-flex justify-content-center">
            <img src={meesh3} className="img-fluid" alt="avatar" />
          </Col>
        </Row>
        <h2 style={{ fontSize: "1.9em" }}>
          <span className="purple"> Fights </span> 
        </h2>
        <Row className="justify-content-center">
          <Col md={4} className="d-flex justify-content-center">
            <img src={meesh2} className="img-fluid" alt="avatar" />
          </Col>
          <Col md={4} className="d-flex justify-content-center">
            <img src={meesh5} className="img-fluid" alt="avatar" />
          </Col>
        </Row>

      </Container>
    </Container>
  );
}

export default MMA;
